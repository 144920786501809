import {useState} from 'react';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';
import {showErrorNotification} from '../../services/SecondaryMethods/snackbars';
import {Messages} from '../../services/lang/messages';

const useLocalStorage = <T>({key, initialState}: {key: string; initialState?: T}): [T, (param: T) => void] => {
  const [state, setState] = useState<T>(() => {
    return JSON.parse('' + localStorage.getItem(key)) || initialState;
  });

  const setStateEnchanted = (nextValue: T) => {
    if (isEmptyValue(nextValue)) {
      localStorage.removeItem(key);
    } else {
      try {
        localStorage.setItem(key, JSON.stringify(nextValue));
      } catch (_) {
        showErrorNotification({msg: Messages.Errors.DataSavingError});
      }
    }
    setState(nextValue);
  };

  return [state, setStateEnchanted];
};

export default useLocalStorage;
