import {RowPerPage} from 'services/interfaces/global-interfaces';
import {
  DataSourceConfig,
  ExtendedLoadOptions,
  HashRequestBody,
  IDataSourceCacheStore,
  LoadResult
} from 'utilsOld/datasource/consts';
import DataSourceCacheController from './DataSourceCacheController';
import BaseDataSource from './DataSource';
import {isEmptyObject, isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {APIRequest} from '../../services/requests/types';
import LocalDataSourceCacheStore from './LocalDataSourceCacheStore';

export type ICacheDataSource = DataSourceConfig & {
  cacheStore?: IDataSourceCacheStore
};

export default class CacheDataSource extends BaseDataSource {
  protected rowsPerPage: RowPerPage = 20;
  protected _isLoadingAll: boolean = false;
  private _forcedFilter: Record<string, any> = {};
  // @ts-ignore
  protected cacheController: DataSourceCacheController;

  constructor({cacheStore, ...config}: ICacheDataSource) {
    super(config);
    this.setCacheStore(cacheStore);
  }

  setCacheStore(cacheStore?: IDataSourceCacheStore) {
    this.cacheController = new DataSourceCacheController(cacheStore ?? new LocalDataSourceCacheStore())
  }

  protected cacheRows(data: LoadResult['data'], loadedPage: number) {
    this.cacheController.appendDataToCache(data, loadedPage);
  }

  protected loadData(
    req: Omit<APIRequest, 'Columns'>,
    rowsPerPage?: number
  ): Promise<{
    data: Record<string, any>[];
    pagesPredict: number;
    error: Error | undefined;
    responseId: string;
    codeError: string;
  }> {
    return super.loadData(req, rowsPerPage).then(result => ({
      ...result,
      data: this.processData(result.data),
      pagesPredict: result.pagesPredict
    }));
  }

  protected loadAndCache(req: HashRequestBody, rowsPerPage?: number) {
    return this.loadData(req, rowsPerPage)
      .then(({data, pagesPredict}) => {
        this.cacheRows(data, req.Page!);
        return {data, pagesPredict};
      })
      .catch(e => {
        this.cacheRows([], req.Page!);
        throw e;
      });
  }

  protected processData(data: Record<string, any>[]) {
    return data;
  }

  protected calcSkippedPageCount(skip: number) {
    const pageSize = this.rowsPerPage as number;
    return Math.ceil(skip / pageSize);
  }

  protected async loadAllNeededPages(req: HashRequestBody, skip: number, take: number) {
    if (this.isLoadAll()) {
      if (this.userData.isLastPage) return;

      const nextReq = {...req, Page: -1};
      return this.loadAndCache(nextReq);
    }

    const pageSize = this.rowsPerPage as number;

    const skipPages = this.calcSkippedPageCount(skip);
    let needPages = Math.max(take, pageSize) / pageSize;

    const hasInCache = (page: number) => {
      const firstLoadedPage = this.cacheController.firstLoadedPage;
      const lastLoadedPage = this.cacheController.lastLoadedPage;
      return page >= firstLoadedPage && page <= lastLoadedPage;
    };

    const isLastPage = (page: number) => {
      const firstLoadedPage = this.cacheController.firstLoadedPage;
      const lastLoadedPage = this.cacheController.lastLoadedPage;
      return (page > lastLoadedPage && !this.userData.isLastPage) || (page < firstLoadedPage && page >= 1);
    };

    let i = 1,
      page = skipPages + i;
    while (i <= needPages && isLastPage(page) && !hasInCache(page)) {
      const nextReq = {...req, Page: page};
      await this.loadAndCache(nextReq, pageSize);
      ++page;
      ++i;
    }
  }

  protected async loadDataFromCache(req: HashRequestBody, skip: number, take: number): Promise<LoadResult> {
    await this.loadAllNeededPages(req, skip, take);

    if (this.isLoadAll()) return this.cacheController.loadAll();

    const pageSize = this.rowsPerPage as number;
    const firstLoadedPage = this.cacheController.firstLoadedPage;
    let firstPageIndex = (firstLoadedPage - 1) * pageSize;

    return this.cacheController.load(skip - firstPageIndex, take);
  }

  protected isLoadAll() {
    return this._isLoadingAll || this.rowsPerPage === 'all';
  }

  protected forcedFilter(outFilter: Record<string, any> = {}) {
    for (let key in this._forcedFilter) {
      if (this._forcedFilter.hasOwnProperty(key) && !isEmptyValue(this._forcedFilter[key])) {
        outFilter[key] = this._forcedFilter[key];
      }
    }

    return outFilter;
  }

  protected getRequestObj(_aSort?: {selector: string; desc: boolean}[], _parentIDs?: any): HashRequestBody {
    const filter = this.forcedFilter();

    return {
      Filters: !isEmptyObject(filter) ? filter : undefined,
      Params: this.operationsParams
    };
  }

  protected async load({take, skip}: ExtendedLoadOptions): Promise<LoadResult> {
    const requestObject = this.getRequestObj();

    if (!this.cacheController.hasHash(requestObject)) {
      this.clearCache();
    }

    let rows = this.loadDataFromCache(requestObject, skip, take);

    this.cacheController.hash = requestObject;

    return rows;
  }

  get masterFilter(): Record<string, any> {
    return this._forcedFilter;
  }

  get cachedData() {
    return this.cacheController.loadAll();
  }

  set masterFilter(value: Record<string, any>) {
    this._forcedFilter = value ?? {};
  }

  public clearCache() {
    this.cacheController.clearCache();
    this.userData.isLastPage = false;
  }

  public setRowPerPage(pageSize: number) {
    this.rowsPerPage = pageSize;
  }

  public setCacheSummary(summary: Record<string, any>) {
    this.cacheController.setSummary(summary);
  }
}
