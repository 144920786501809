import {isDefined} from '../../services/SecondaryMethods/typeUtils';

export const booleanToNumber = (value?: boolean) => isDefined(value) ? (value ? 1 : 0) : undefined;

export const numberToBoolean = (value?: Number) => isDefined(value) ? !!value : undefined;

export const parseAndConvertState = <T>(state: string, convertFunc: (args: T) => any) => {
  const parsedState = JSON.parse(state);
  const convertedState = convertFunc(parsedState);

  return JSON.stringify(convertedState);
}
