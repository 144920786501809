import {FIELD_EDITOR_TYPE, FILTER_OPERATIONS, FILTER_OPERATIONS_ID} from '../../services/interfaces/global-interfaces';
import {OneOrZero} from './convertColumns';
import {booleanToNumber, numberToBoolean} from './utils';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';

interface MaximizeFilter {
  value: any;
  operation: FILTER_OPERATIONS;
  hasIsBlank: boolean;
  type: FIELD_EDITOR_TYPE;
  isTable: boolean;
  requestField: string;
  isCustomizable: boolean;
  isCustomConditionList: boolean;
  customConditionList: Array<FILTER_OPERATIONS_ID>;
}

interface MinimizeFilter {
  v: any;
  o: FILTER_OPERATIONS;
  hib: OneOrZero;
  t: FIELD_EDITOR_TYPE;
  it: OneOrZero;
  rf: string;
  ic: OneOrZero;
  iccl: OneOrZero;
  ccl: Array<FILTER_OPERATIONS_ID>;
}

export const minimizeFilters = (filters: Record<string, MaximizeFilter>): Record<string, MinimizeFilter> => {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key];

    const isAlreadyMinimized = Object.keys(filter).some((key) => ['t', 'o'].includes(key));

    if (isAlreadyMinimized) return {
      ...acc,
      [key]: filter
    };

    return {
      ...acc,
      [key]: {
        v: filter.value,
        o: filter.operation,
        hib: booleanToNumber(filter.hasIsBlank),
        t: filter.type,
        it: booleanToNumber(filter.isTable),
        rf: filter.requestField,
        ic: booleanToNumber(filter.isCustomizable),
        iccl: booleanToNumber(filter.isCustomConditionList),
        ccl: filter.customConditionList
      }
    }
  }, {})
}

export const maximizeFilters = (filters: Record<string, MinimizeFilter>): Record<string, MaximizeFilter> => {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key];

    if (isEmptyValue(filter)) {
      return {
        ...acc,
        [key]: filter
      }
    }

    return {
      ...acc,
      [key]: {
        value: filter.v,
        operation: filter.o,
        hasIsBlank: numberToBoolean(filter.hib),
        type: filter.t,
        isTable: numberToBoolean(filter.it),
        requestField: filter.rf,
        isCustomizable: numberToBoolean(filter.ic),
        isCustomConditionList: numberToBoolean(filter.iccl),
        customConditionList: filter.ccl
      }
    }
  }, {})
}
