import {getRow} from '../elems/listForm/D5Row';
import {stylesRowWrapper} from 'middlewares/userScript/elems/stylesWrapper';
import {fields} from '../../../services/objects';
import {runFormScriptSync} from '../utils';
import {SysFormWrapper} from '../../../utilsOld/systemObjects';
import {getEvent} from '../utils/getEvent';

export const onCustomizeRow = ({
  name,
  rowData,
  processedForm,
  rowIndex,
  isSelected,
  isExpanded,
  trHtmlElement,
  customizeRow,
  eventType
}) => {
  const {sysForm} = processedForm;
  const eventName = getEvent({sysForm, name, eventType});
  const wrapper = new SysFormWrapper(sysForm);

  const keyFld = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const row = getRow(rowData, keyFld);

  const rowStyles = {};

  const result = runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    rowStyles,
    args: [
      {
        row,
        style: stylesRowWrapper(trHtmlElement, rowStyles),
        rowIndex,
        isSelected,
        isExpanded,
        trHtmlElement
      }
    ]
  });

  customizeRow[row.key] = {
    styles: {...rowStyles},
    rowIndex
  };

  return result;
}
