import {StorageVisibleFilter} from '../../components/FilterPanel/FilterSettings/types';
import {booleanToNumber, numberToBoolean} from './utils';
import {OneOrZero} from './convertColumns';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';

export interface MinimizeVisibleFilter {
  i: number;
  iv: OneOrZero;
  ic?: OneOrZero;
  o: number;
}

type MaximizeVisibleFilters = StorageVisibleFilter[];

export const minimizeVisibleFilters = (visibleFilters: MaximizeVisibleFilters): MinimizeVisibleFilter[] => {
  return visibleFilters.map(filter => {
    const isAlreadyMinimized = Object.keys(filter).some((key) => ['i', 'gid'].includes(key));

    if (isAlreadyMinimized) return filter as unknown as MinimizeVisibleFilter;

    return ({
      i: filter.id,
      iv: booleanToNumber(filter.isVisible) as OneOrZero,
      ic: booleanToNumber(filter.isCustomizable),
      o: filter.order
    })
  });
};

export const maximizeVisibleFilters = (visibleFilters: MinimizeVisibleFilter[]): MaximizeVisibleFilters => {
  return isEmptyValue(visibleFilters)
    ? []
    : visibleFilters.map(filter => ({
        id: filter.i,
        isVisible: numberToBoolean(filter.iv) as boolean,
        isCustomizable: numberToBoolean(filter.ic),
        order: filter.o
      }));
};
