import D5DataSource from './D5DataSource';
import {arrayToObject} from '../valueCasters';
import {StoreChanges} from './BaseViewDataSource';
import DataSourceCacheController from './DataSourceCacheController';

export default class D5DataSourceCacheController  {
  private _dataSource: D5DataSource | undefined;
  private keyField: string = '';
  private useUpdateOnPush: boolean = true;
  // @ts-ignore
  private _cacheController: DataSourceCacheController;

  setCacheController(cacheController: DataSourceCacheController) {
    this._cacheController = cacheController;
    return this;
  }

  setUseUpdateOnPush(useUpdateOnPush: boolean) {
    this.useUpdateOnPush = useUpdateOnPush;
    return this;
  }

  setKeyField(keyField: string) {
    this.keyField = keyField;
    return this;
  }

  setDataSource(dataSource: D5DataSource) {
    this._dataSource = dataSource;
    return this;
  }

  public overrideStorePush() {
    const dataSourceStore = this._dataSource!.store();
    const defaultPushMethod = dataSourceStore.push;
    this._dataSource!.store().push = changes => {
      const updates = changes.filter(change => change.type === 'update');
      const inserts = changes.filter(change => change.type === 'insert');
      const removes = changes.filter(change => change.type === 'remove');
      const {data} = this._cacheController.loadAll();

      let newData = this.updateRowsOnPush(updates, data);
      newData = this.insertsRowsOnPush(inserts, newData);
      newData = this.removeRowsOnPush(removes, newData);

      //тут потрібно виключити updates, тому що воно повністю перемальовує картку при push
      //ми зробили селектор і воно бере дані з редакс, тим самим вся картка не перемальовується
      defaultPushMethod.call(dataSourceStore, this.useUpdateOnPush ? changes : [...inserts, ...removes]);
      this._cacheController.replaceCache(newData);
    };
  }

  private insertsRowsOnPush(inserts: StoreChanges, data: Array<Record<any, any>>) {
    for (const insert of inserts) {
      data.splice(insert.index!, 0, insert.data!);
    }
    return data;
  }

  private removeRowsOnPush(removes: StoreChanges, data: Array<Record<any, any>>) {
    const removesMap = arrayToObject(removes, 'key');
    if (!removes.length) return data;
    return data.filter(rowData => !removesMap.hasOwnProperty(rowData[this.keyField]));
  }

  private updateRowsOnPush(updates: StoreChanges, data: Array<Record<any, any>>) {
    const updatesMap = arrayToObject(updates, 'key');
    return data.map(rowData => {
      const key = rowData[this.keyField];
      if (updatesMap.hasOwnProperty(key)) {
        return updatesMap[key].data!;
      }

      return rowData;
    });
  }
}
