import React from 'react';
import DataGrid from 'devextreme/ui/data_grid';
/**
 * Общий интерфейс для всех элементов на лейауте
 */
import {dxTextEditorButton} from 'devextreme/ui/text_box/ui.text_editor.base';

import {
  BUTTON_POSITION,
  BUTTON_TYPE,
  DECORATION_DISPLAY_TYPE,
  FIELD_EDITOR_TYPE,
  FILTER_LAYOUT,
  FILTER_OPERATIONS,
  FILTER_OPERATIONS_ID,
  FormType,
  GroupType,
  HorizontalAlign,
  ListViewItemMenuMode,
  OrientationStr,
  PAGINATION_TYPE,
  ShowScrollbarMode,
  SplitterHandle,
  TEXT_ALIGNMENT,
  TileViewDirection,
  TitleType,
  TOOLBAR_VIEW_TYPE,
  USmallInt,
  VerticalAlign
} from 'services/interfaces/global-interfaces';
import {FilterField, FormField} from 'utilsOld/systemObjects';
import DataSource from 'devextreme/data/data_source';
import TableCacheDataSource from 'utilsOld/datasource/TableCacheDataSource';
import {SysSubForm} from 'services/interfaces/sysObjects/SysSubForm';
import {SysForm, SysFormFields} from 'services/interfaces/sysObjects';
import {auto, colRangeNumber} from 'utilsOld/gridUtils';
import D5SummaryRow, {D5TreeSummaryRow} from 'components/TableCoreOld/TotalRow/summary_row';
import {IHintState} from '../../hint/types';
import {OnFilterValueChanged} from '../../currentForms/types';
import {ToolBarItemWidget} from '../../../utilsOld/toolbar_buttons';
export interface IBaseLayoutElement {
  id: number;
  isVisible: boolean;
  name: string;
  order: number;
  parentID: number | null;
}

export interface ITextEditorButton extends dxTextEditorButton {
  id: number;
  isVisible: boolean;
  options: dxTextEditorButton['options'] & {
    title: string;
    colorScheme: ButtonColorScheme | undefined;
    stylingMode: ButtonStylingMode | undefined;
  };
}

export type LayoutButton = ILayoutItem<IButtonOptions>;
export type LayoutButtons = LayoutButton[];

export type LayoutGroup = ILayoutItem<IGroupOptions>;
export type LayoutFieldItem = ILayoutItem<IFieldOptions>;
export type LayoutFilterItem = ILayoutItem<IFilterOptions>;
export type LayoutGroups = LayoutGroup[];

/**
 * общий интерфейс для всех items
 * у каждого свой options который создаётся внутри get<Item> function
 */
export interface ILayoutItem<IItemOptions = any> extends IBaseLayoutElement {
  creator: ButtonCreators;
  groupID?: number; //что-то страрое мб нужно удалить, как-то
  isReadOnly?: boolean;
  isReadOnlyForm?: boolean;
  captionStyles?: {
    [key: string]: number | string;
  };
  itemType: ELayoutType;
  options: IItemOptions;
  displayType?: number;
  isShowTitle?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isCustomizable?: boolean;
  isFormBodyScrollable?: boolean;
  hasScroll?: boolean;
  cols?: colRangeNumber | auto;
  offset?: colRangeNumber;
  newLine?: boolean;
  formID?: string;
  rows?: number; //TODO Grid del
  component?: React.FC<ILayoutItem<any>>;
  onInitFunction?: any;
  className?: string;
  idAttr?: string;
  minWidth?: string;
  minHeight?: string;
  parentOrientation?: string;
  items?: ILayoutItem<IGroupOptions | TItem>[];
  formKey?: string;
  headerFilter?: boolean;
  location?: 'after' | 'before'; //location for button with position 5
  formFieldID?: number; //formFieldID for button with position 5
  hint?: string;
  help?: string;
  helpArticleGuid?: string;
  docUrl?: string;
  showHint?: VoidFunction;
  removeHint?: VoidFunction;
  beforeCaption?: HTMLElement;
  afterCaption?: HTMLElement;
  locateInMenu?: string;
  groupType?: number;
  widget?: ToolBarItemWidget;
  visible?: boolean;
  beginGroup?: boolean;
  title?: string;
  isShowLastValues?: boolean;
  lastValuesCount?: number;
  customConditionListInDependOnForm?: Record<string, any> | null;
  clearIndicator?: {
    visible: boolean;
    disabled: boolean;
  };
}

export interface IDecorOptions {
  displayCustomParam: string;
  displayType: DECORATION_DISPLAY_TYPE;
  formID: string;
  lineCount: number | null;
  style?: string;
  title: string;
  titleFont?: string;
  titleFontSize?: number;
  titleColor?: string;
  titleAlignment?: string;
  width: number | null;
  hasScroll?: boolean;
  titleFontStyle: string;
  textFontSize: number | null;
  textAlignment: string;
  textFontStyle: string;
  text: string;
  isShowTitle: boolean;
  label: ILabel;
  isResizable: boolean;
  isAdaptiveHeight: boolean;
  height: number;
  maxHeight: number;
}

export interface IFocusedField {
  name: string;
  itemType: ELayoutType;
}

export enum ButtonColorScheme {
  Default,
  Primary,
  Success,
  Danger,
  Warning,
  MainScheme
}

export enum ButtonColorSchemeType {
  Default = 'default',
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  MainScheme = 'main scheme'
}

export enum ButtonStylingMode {
  None,
  Outlined,
  Contained
}

export enum ButtonStylingModeType {
  None = 'none',
  Outlined = 'outlined',
  Contained = 'contained'
}

export interface IButtonOptions {
  actionFormID: string | undefined;
  actionFormName: string | undefined;
  actionFormObjectName: string | undefined;
  actionFormType: number | undefined;
  actionFormViewMode: number | undefined;
  buttonFillingRules?: Record<string, any>[] | undefined;
  buttonType: BUTTON_TYPE;
  customOperation: string | undefined;
  customParameters: string | undefined;
  className?: string;
  disabled?: boolean;
  elementAttr?: Record<string, any>;
  formID: string;
  formIDType?: number;
  formOpenMode: number | undefined;
  icon: string | undefined;
  iconRender?: (iconType: string) => JSX.Element;
  id?: string | number;
  isPressed?: boolean;
  isHideInOtherActions: boolean;
  onClick?: (e: any, name: string) => any;
  position: BUTTON_POSITION;
  style?: string;
  title: string;
  defaultIcon: string;
  defaultTitle: string;
  text?: string;
  clickEventName: string | undefined;
  titleType: TitleType;
  buttonWidth: string | undefined;
  isEditButtonByDefault: boolean;
  isCustomizable: boolean;
  attachedReportId?: number;
  beginGroup: boolean;
  hotkey?: string;
  hint?: string;
  docUrl?: string;
  width?: string;
  colorScheme: ButtonColorScheme;
  stylingMode: ButtonStylingMode;
  isDisabled?: boolean;
  visible?: boolean;
}

export interface IReportList {
  id: number;
  name: string;
  title: string;
  dialogActionFormName?: string;
}

export interface IGroupOptions {
  title: string;
  cssClass?: string;
  groupType: GroupType;
  formID: string;
  icon: string | null;
  defaultIcon: string;
  defaultTitle: string;
  isCollapsed: boolean;
  isCustomizable: boolean;
  isActive: boolean;
  isGroup: boolean;
  beginGroup: boolean;
  isShowTitle: boolean;
  orientation: OrientationStr;
  splitter: SplitterHandle;
  titleType: TitleType;
  width: string | null;
  defaultWidth?: number | string;
  defaultHeight?: number | string;
  fieldTitleDefaultWidth?: string;
  isHideInOtherActions?: boolean; //только для тулбара
  isResizable: boolean;
  verticalAlign: VerticalAlign;
  horizontalAlign: HorizontalAlign;
  position?: BUTTON_POSITION;
  tabPosition?: number;
  docUrl?: string;
  helpArticleGuid?: string;
  hint?: string;
  colorScheme: ButtonColorScheme;
  stylingMode: ButtonStylingMode;
  displayCustomParam?: string;
  IsGroupBodyScrollable: number;
  dataTestId: string;
  titleFontStyle: string;
  disabled?: boolean;
}

export interface ISubFormOptions {
  id: string;
  formID: string;
  parentFormID: string | null;
  nestedFieldName: string | null;
  formType: FormType;
  name: string;
  formKey: string;
  isDockPanel: boolean;
}

interface IFormOptions {
  id: string;
  formID: string;
  parentFormID: string | null;
  toolBarViewType: TOOLBAR_VIEW_TYPE;
  name: string;
  defaultFilter: any;
  isSubForm: boolean;
  formKey: string;
  hasScroll?: boolean;
}

export interface ITableOptions extends IFormOptions {
  formType: FormType;
  nestedFieldName: string | null;
  cacheDataSource: TableCacheDataSource | null;
  paginationType: PAGINATION_TYPE;
  totalRow: D5SummaryRow | D5TreeSummaryRow;
  defaultWidth: number;
  defaultHeight: number;
}

export interface ISchedulerOptions extends IFormOptions {}

export interface ITileListOptions extends IFormOptions {
  tileViewDefaultGroupDisplayExpr: string;
  tileViewDefaultGroupKeyExpr: string;
  tileViewDefaultGroupNameExpr: string;
  tileViewDefaultGroupObjectExpr: string;
  tileViewGroupStylingMode: number;
  tileViewGroupsPerPage: number;
  tileViewTilesPerPage: number;
  tileViewRowsPerGroup: number;

  tileViewBaseItemHeight: number;
  tileViewBaseItemWidth: number;
  tileViewIsUseGroupButton: USmallInt;
  tileViewDirection: TileViewDirection;
  tileViewHeightRatioFieldExpr: string;
  tileViewItemMargin: number;
  tileViewShowScrollbarMode: ShowScrollbarMode;
  tileViewWidthRatioFieldExpr: string;
}

export interface IListViewOptions extends IFormOptions {
  listViewIsMultiselect: boolean;
  listViewIsSelectionEnabled: boolean;
  listViewItemContentPadding: string | undefined;
  listViewItemHeight: number;
  listViewItemMargin: number;
  listViewItemMenuMode: ListViewItemMenuMode;
  listViewMaxRowCount: number;
  listViewShowScrollbarMode: ShowScrollbarMode;
}

export interface IKanbanOptions extends IFormOptions {}

export interface IFilterOptions {
  defaultOperation: FILTER_OPERATIONS;
  defaultValue: any;
  allowNullState: boolean;
  dataTestId: string;
  filterField: FilterField;
  lsKey: string;
  objectName: string;
  objectFieldID: string;
  displayType: number;
  onValueChanged: OnFilterValueChanged;
  onEnterKey?: Function;
  realOperation: FILTER_OPERATIONS;
  hasIsBlank: boolean;
  isShowInMainForm: boolean;
  validationGroup: string;
  fieldType: number;
  value?: any;
  formID?: string;
  formKey: string;
  filter: Record<string, any>;
  operationsParams: Record<string, any>;
  dataSource?: DataSource;
  displayExpr?: string;
  displayValue?: string;
  applyValueMode?: any;
  valueExpr?: string;
  label: ILabel;
  headerFilter?: boolean;
  layout: FILTER_LAYOUT;
  buttons?: ILayoutItem<IButtonOptions>[];
  width?: string | number | null;
  height?: string | number | null;
  position?: string | number | null;
  titlePosition: Location;
  borderStyle?: BorderStyleStr;
  titleFontStyle: string;
  isDisabled: boolean;
  isAnyOf: boolean;
  isMultiSelect: boolean;
  sortValues: string[];
  conditionList: FILTER_OPERATIONS_ID[];
  isCustomConditionList: boolean;
  isSchedulerStartDateFilter: boolean;
  d5Type: FIELD_EDITOR_TYPE;
  dxType: string;
  titleFont: string | null;
  titleColor: string | null;
  titleAlignment: string | null;
  titleFontSize: number | null;
  textAlignment: TEXT_ALIGNMENT;
  lookupTagColorSchemeFieldName?: string;
  lookupTagStylingModeFieldName?: string;
  displayCustomParam?: string;
}

export type IFieldAsFilterOptions = IFilterOptions & {
  filterField: FormField;
};

export interface IFieldOptions {
  displayCustomParam: string;
  objectName: string;
  displayType: number;
  onValueChanged?: (_: {
    value: any;
    dxType: string;
    dataField: string;
    field: FormField;
    displayValue: string | undefined;
    withUSEvent?: boolean;
  }) => void;
  onEnterKey?: (_: {inputEvent: any; field: FormField}) => void;
  onKeyDown?: (_: {inputEvent: any; field: FormField}) => void;
  onFocusOut?: (_: {inputEvent: any; field: FormField}) => void;
  onValidation?: (_: {value: any}) => Promise<void>;
  value?: any;
  formID: string;
  //export diagram as svg
  exportAsSVG?: () => Promise<string>;
  formKey: string;
  filter: Record<string, any>;
  operationsParams: Record<string, any>;
  field: FormField;
  validationGroup: string;
  events?: Record<string, any>[];
  lineCount?: number;
  displayField?: number;
  displayValue?: string;
  dictObj?: string;
  isResizable: boolean;
  isFile: boolean;
  hasScroll?: boolean;
  isMultiSelect: boolean;
  isAdaptiveHeight: boolean;
  isAnyOf: boolean;
  dataSource?: DataSource;
  createDataSource?: () => DataSource;
  displayExpr?: string;
  valueExpr?: string;
  label: ILabel;
  formForSelectByDefault?: number;
  isSubForm?: boolean;
  parentFieldID?: number;
  isParentField: boolean;
  isCopy: boolean;
  isDynamic: boolean;
  isDisabled: boolean;
  isReadOnly?: boolean;
  width?: string | number;
  height?: string | number | null;
  fieldType: number;
  titlePosition: Location;
  titleOrientation: 'vertical' | 'horizontal';
  borderStyle?: BorderStyleStr;
  titleFontStyle: string;
  sortValues: string[];
  dataTestId: string;
  lookupWithoutArray?: boolean;
  d5Type: FIELD_EDITOR_TYPE;
  dxType: string;
  textAlignment: TEXT_ALIGNMENT;
  lookupTagColorSchemeFieldName?: string;
  lookupTagStylingModeFieldName?: string;
  viewFormat?: string;
  dataFieldId?: number;
  titleFont: string | null;
  titleColor: string | null;
  titleAlignment: string | null;
  titleFontSize: number | null;
  textFont: string | null;
  textFontSize: number | null;
  iconColor?: string;
  iconSize?: string;
  isDoNotSend: boolean;
}

export interface ILabel {
  text: string;
  visible: boolean;
  location: string;
  titleWidth?: string;
}

export interface ICellInfo {
  value: any;
  key: number;
  component: DataGrid;
  rowIndex: number;
  column: Record<string, any>;
  row: Record<string, any>;
  data: Record<string, any>;
}

export type Location = 'left' | 'right' | 'top' | 'on_border' | 'floating';

export type TItem = IFieldOptions | IFilterOptions | IButtonOptions | IDecorOptions | ISubFormOptions;

export type showHintFunc = ({target, position, hint}: IHintState) => void;

export type ExtendedSubForm = SysSubForm & {
  formType: FormType;
  toolBarViewType: TOOLBAR_VIEW_TYPE;
  isSubForm: boolean;
  sysForm: SysForm;
};

export enum ButtonCreators {
  FORM = 'form',
  USERSCRIPT = 'userscript'
}

export enum ELayoutType {
  FILTER = 'filter',
  DECORATION = 'decoration',
  FORM_FIELD = 'form_field',
  BUTTON = 'button',
  SUBFORM = 'subform',
  GROUP = 'group',
  TABLE = 'table',
  KANBAN = 'kanban',
  SCHEDULER = 'scheduler',
  TileList = 'tilelist',
  ListView = 'listView',
  NAV_EDIT = 'nav-edit',
  SUBSYSTEM_ITEM = 'subsystem-item'
}

export type FilterItem = ILayoutItem<IFilterOptions>;

export interface BoolPartProps {
  field: FormField;
  isNestedTable: boolean;
  preventLoading?: boolean;
  updateNested: (component: DataGrid) => void;
  viewSource: SysFormFields[];
  formKey: string;
  component?: any;
  key?: number;
  value?: any;
  row?: any;
}

export enum BorderStyleStr {
  None = 'none',
  Underline = 'underline',
  Default = 'default'
}

export enum LocationStr {
  BEFORE = 'before',
  AFTER = 'after'
}
