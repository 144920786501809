export enum GlobalEvents {
  DrawerResizeEND = 'drawer/resizeEnd',
  DrawerClosed = 'drawer/closed',
  DrawerOpened = 'drawer/opened',
  FormFilterApply = 'form/filterApply',
  ModalResizeEnd = 'modal/resizeEnd',
  ModalShown = 'modal/shown',
  SplitterResizeEnd = 'splitter/resizeEnd',
  AfterScrollClose = 'dropdown/afterScrollClose',
  WindowResize = 'window/resize',
}

export type DrawerAction = Record<string, any>;
export type SplitterAction = Record<string, any>;
export type ModalAction = {formID?: string};
export type FormFilterApplyAction = {formName: string};
export type AfterScrollCloseAction = Record<string, any>;
export type WindowResizeAction = Record<string, any>;

export type DrawerActionCallback = (e: DrawerAction) => void;
export type SplitterActionCallback = (e: SplitterAction) => void;
export type FormFilterApplyActionCallback = (e: FormFilterApplyAction) => void;
export type ModalActionCallback = (e: ModalAction) => void;
export type AfterScrollCloseActionCallback = (e: AfterScrollCloseAction) => void;
export type WindowResizeActionCallback = (e: WindowResizeAction) => void;

/**
 * TODO
 * Убрать дублирование кода.
 * Убрать обязательный параметр в колбеке и/или publish
 */

export interface GlobalEventsActions {
  [GlobalEvents.DrawerOpened]: DrawerAction;
  [GlobalEvents.DrawerResizeEND]: DrawerAction;
  [GlobalEvents.DrawerClosed]: DrawerAction;
  [GlobalEvents.FormFilterApply]: FormFilterApplyAction;
  [GlobalEvents.ModalResizeEnd]: ModalAction;
  [GlobalEvents.ModalShown]: ModalActionCallback;
  [GlobalEvents.SplitterResizeEnd]: SplitterAction;
  [GlobalEvents.AfterScrollClose]: AfterScrollCloseAction;
  [GlobalEvents.WindowResize]: WindowResizeAction;
}

export interface GlobalEventsCallbackTypes {
  [GlobalEvents.DrawerOpened]: DrawerActionCallback;
  [GlobalEvents.DrawerResizeEND]: DrawerActionCallback;
  [GlobalEvents.DrawerClosed]: DrawerActionCallback;
  [GlobalEvents.FormFilterApply]: FormFilterApplyActionCallback;
  [GlobalEvents.ModalResizeEnd]: ModalActionCallback;
  [GlobalEvents.ModalShown]: ModalActionCallback;
  [GlobalEvents.SplitterResizeEnd]: SplitterActionCallback;
  [GlobalEvents.AfterScrollClose]: AfterScrollCloseActionCallback;
  [GlobalEvents.WindowResize]: WindowResizeActionCallback;
}

export interface GlobalEventsData {
  [GlobalEvents.DrawerOpened]: DrawerActionCallback[];
  [GlobalEvents.DrawerResizeEND]: DrawerActionCallback[];
  [GlobalEvents.DrawerClosed]: DrawerActionCallback[];
  [GlobalEvents.FormFilterApply]: FormFilterApplyActionCallback[];
  [GlobalEvents.ModalResizeEnd]: ModalActionCallback[];
  [GlobalEvents.ModalShown]: ModalActionCallback[];
  [GlobalEvents.SplitterResizeEnd]: SplitterActionCallback[];
  [GlobalEvents.AfterScrollClose]: AfterScrollCloseActionCallback[];
  [GlobalEvents.WindowResize]: WindowResizeActionCallback[];
}
