import {TVLocalStorageColumn} from '../../components/TableCoreOld/TableView';
import {booleanToNumber, numberToBoolean} from './utils';

export type OneOrZero = 1 | 0;

interface MinimizeColumn {
  gid: number | null;
  gpid: number | null;
  i: number;
  f?: OneOrZero;
  fp?: 'left' | 'right';
  w?: string | number;
  so?: 'asc' | 'desc';
  si?: number;
  vi?: number;
  v?: OneOrZero;
  ivoedp?: OneOrZero;
  df?: string;

  //Только у динамических колонок
  idc: OneOrZero;
  //Только у динамических колонок для управления отображением
  whbu?: OneOrZero;
  uc?: OneOrZero;
  icc?: OneOrZero;
}

type MaximizeColumns = TVLocalStorageColumn[];


export const minimizeColumns = (columns: MaximizeColumns): MinimizeColumn[] => {
  return columns.map(col => {
    const isAlreadyMinimized = Object.keys(col).some((key) => ['i', 'gid'].includes(key));

    if (isAlreadyMinimized) return col as unknown as MinimizeColumn;

    return {
      gid: col.groupID,
      gpid: col.groupParentID,
      i: col.index,
      f: booleanToNumber(col.fixed),
      fp: col.fixedPosition,
      w: col.width,
      so: col.sortOrder,
      si: col.sortIndex,
      vi: col.visibleIndex,
      v: booleanToNumber(col.visible),
      ivoedp: booleanToNumber(col.isVisibleOnEditDockPanel),
      df: col.dataField,
      idc: booleanToNumber(col.isDynamicColumn) as OneOrZero,
      whbu: booleanToNumber(col.wasHiddenByUser),
      uc: booleanToNumber(col.userCreated),
      icc: booleanToNumber(col.isCombinedColumn)
    }
  })
}

export const maximizeColumns = (columns: MinimizeColumn[]): MaximizeColumns => {
  return columns.map(col => ({
    groupID: col.gid,
    groupParentID: col.gpid,
    index: col.i,
    fixed: numberToBoolean(col.f),
    fixedPosition: col.fp,
    width: col.w,
    sortOrder: col.so,
    sortIndex: col.si,
    visibleIndex: col.vi,
    visible: numberToBoolean(col.v),
    isVisibleOnEditDockPanel: numberToBoolean(col.ivoedp),
    dataField: col.df,
    isDynamicColumn: numberToBoolean(col.idc) as boolean,
    wasHiddenByUser: numberToBoolean(col.whbu),
    userCreated: numberToBoolean(col.uc),
    isCombinedColumn: numberToBoolean(col.icc)
  }))
}
