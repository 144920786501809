import CacheDataSource from './CacheDataSource';
import D5DataSource from './D5DataSource';
import {DataSourceConfig, ExtendedLoadOptions} from './consts';
import {applyFilterFromUserScript, getLoadParams} from '../createFieldDataSource';
import {SearchModes} from 'components/newReactFormFields/types';
import {isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import D5DataSourceCacheController from './D5DataSourceCacheController';

type Config = DataSourceConfig & {key: string; searchField: string};

export const REPAINT_TIME_OUT = 100;

export interface IListSelectorDataSource {
  dataSource: D5DataSource;

  exclude(keys: any[]): void;

  byKey(keys: any[]): Promise<any[]>;

  getAllRecords(): Record<string, any>[];

  filter(flt: any): void;

  reload(): Promise<void>;
}

export default class ListSelectorDataSource extends CacheDataSource implements IListSelectorDataSource {
  private readonly _dataSource: D5DataSource;
  private readonly _keyField: string;
  protected _d5CacheController: D5DataSourceCacheController;

  constructor(config: Config) {
    super(config);
    this._keyField = config.key;

    this._dataSource = new D5DataSource({
      key: this._keyField,
      searchExpr: config.searchField,
      pushAggregationTimeout: REPAINT_TIME_OUT,
      load: loadOptions => {
        const userFilter = applyFilterFromUserScript(loadOptions.filter);
        this._isLoadingAll = !!(loadOptions as ExtendedLoadOptions).isLoadingAll;
        if (this._dataSource?.customSearchOperation) {
          const searchValue = loadOptions.searchValue ?? '';
          const searchOperation = this._dataSource.customSearchOperation;
          const {operation, value} = getLoadParams(searchValue)[searchOperation] ?? {};
          if (!isEmptyValue(value) && !isEmptyValue(operation)) {
            this.masterFilter = {
              ...this.masterFilter,
              ...userFilter,
              [config.searchField]: {[operation!]: value},
            };
          } else {
            delete this.masterFilter[config.searchField];
            this.masterFilter = {
              ...this.masterFilter,
              ...userFilter,
            };
          }
        }
        if (loadOptions.skip && loadOptions.skip < 0) loadOptions.skip = 0;

        return this.load(loadOptions as ExtendedLoadOptions);
      },
    });
    this._dataSource.UNSAFE_getAllRowsData = () => this.getAllRecords();

    this._d5CacheController = new D5DataSourceCacheController()
      .setCacheController(this.cacheController)
      .setKeyField(this._keyField)
      .setDataSource(this._dataSource);

    this._d5CacheController.overrideStorePush();
  }

  get dataSource() {
    return this._dataSource;
  }

  filter(flt: any) {
    this._dataSource.filter(flt);
  }

  reload(): Promise<void> {
    this.clearCache();
    return this._dataSource.reload();
  }

  exclude(keys: any[]) {
    if (!keys.length) return delete this.masterFilter[this._keyField];

    this.masterFilter = {
      [this._keyField]: {'!=': keys},
    };
  }

  async byKey(keys: any[]) {
    const rows = this.getAllRecords().filter(row => keys.includes(row[this._keyField]));
    const keysLeft = keys.filter(key => !rows.some(row => row[this._keyField] === key));

    if (!keysLeft.length) return rows;

    const loadedRows = await this.loadData({
      Filters: {
        [this.dataSource.key() as string]: {'=': keysLeft},
      },
      Page: -1,
    }).then(({data}) => data ?? []);

    return rows.concat(loadedRows);
  }

  getAllRecords(): Record<string, any>[] {
    return this.cacheController.loadAll().data;
  }
}

export class ListSelectorArrayStore implements IListSelectorDataSource {
  key: string;
  private readonly _dataSource: D5DataSource;

  constructor(config: {key: string; store: any[]}) {
    this.key = config.key;
    this._dataSource = new D5DataSource({
      key: this.key,
      store: {
        type: 'array',
        key: config.key,
        data: config.store,
      },
      pushAggregationTimeout: REPAINT_TIME_OUT,
      postProcess: data => {
        const searchValue: string = this._dataSource.searchValue()?.toLowerCase() ?? '';
        const searchField = this._dataSource.searchExpr();
        const searchOperation = this._dataSource.customSearchOperation;
        if (!searchValue || !searchOperation) {
          return data;
        }

        return this.getAllRecords().filter(row => {
          const value = String(row[searchField]).toLowerCase();
          switch (searchOperation) {
            case SearchModes.STARTS_WITH:
              return value.indexOf(searchValue) === 0;
            case SearchModes.BY_WORDS:
              return searchValue.split(' ').every(word => value.indexOf(word) !== -1);
            default:
              return value.indexOf(searchValue) !== -1;
          }
        });
      },
    });
    this._dataSource.UNSAFE_getAllRowsData = () => this.getAllRecords();
  }

  get dataSource() {
    return this._dataSource;
  }

  exclude(ids: any[]) {
    const filter = ids
      .map(id => [this.key, '<>', id])
      .reduce((res, value, i) => {
        res.push(value);

        if (ids.length - 1 > i) {
          res.push('and');
        }
        return res;
      }, []);
    this.dataSource.filter(filter.length ? filter : undefined);
    this.dataSource.reload();
  }

  filter(flt: any) {
    this._dataSource.filter(flt);
  }

  reload(): Promise<void> {
    return this._dataSource.reload();
  }

  getAllRecords(): Record<string, any>[] {
    // @ts-ignore
    return this.dataSource.store()._array || [];
  }

  byKey(keys: any[]) {
    if (!keys.length) return Promise.resolve([]);
    return Promise.resolve(this.getAllRecords().filter(item => keys.includes(item[this.key])));
  }
}
