import {Column} from 'components/TableCoreOld/columns/index';
import {columnType} from 'utilsOld/helpers';
import {cellStyle, getMaxHeight} from './cellMethods';
import HeaderFilterDataSource from './HeaderFilterDataSource';
import {formatText} from 'utilsOld/valueCasters';
import {FIELD_EDITOR_TYPE} from 'services/interfaces/global-interfaces';
import {minMaxZoomLevel} from '../../../utilsOld/dateUtils';
import {ColumnClass} from '../types';

export const getDateBoxIconTemplate = (isTimeAllowed = false) => {
  return () => {
    const div = document.createElement('div');
    const icon = document.createElement('i');

    div.classList.add('calendar-icon-container');
    icon.classList.add('dx-icon', 'far', isTimeAllowed ? 'fa-clock' : 'fa-calendar', 'button-icon-color');
    div.appendChild(icon);

    return div;
  };
};

// в шаблон передается text вместо value, потому что text - отформатированное библиотекой value
const cellTemplate = (cellElement, {text}) => {
  const dateContainer = document.createElement('span');
  dateContainer.innerHTML = text;
  cellStyle(dateContainer, '1', getMaxHeight(1));
  cellElement.appendChild(dateContainer);
};
export default class DateColumn extends Column {
  toDateTime(val) {
    return new Date(val).getTime();
  }

  sortHeaderFilterDS = (first, second) => {
    if (this.toDateTime(first.value) < this.toDateTime(second.value)) {
      return -1;
    }
    if (this.toDateTime(first.value) > this.toDateTime(second.value)) {
      return 1;
    }
    return 0;
  };

  prettyTextValue = value => {
    const {DATETIME, DATE} = FIELD_EDITOR_TYPE;
    const type = this.field.isTimeAllowed ? DATETIME : DATE;
    return (value || '') && formatText(type, value, this.regSetting);
  };

  headerFilterLoadFun = instance => {
    const ds = new HeaderFilterDataSource({
      loadRows: instance.getAllRowsData.bind(instance),
      valueGetter: this.headerFilterValueGetter,
      textPrettier: this.prettyTextValue,
      sorter: this.sortHeaderFilterDS
    });
    return ds.createLoadFun();
  };

  onInitialized({component}) {
    //Отключение функции изменения значения даты при прокрутке колеса мыши
    //Сделано по задаче 0075341
    component._onMouseWheel = () => null;
  }

  getCssClass() {
    return `${ColumnClass.Date}${this.field.isTimeAllowed ? ' with-time' : ''}`;
  }

  prop() {
    const weekOrMonth = this.field.isMonthDateMode || this.field.isWeekDateMode;

    return {
      ...super.prop(),
      dataType: columnType(this.field.fieldType, this.field.isTimeAllowed),
      cssClass: this.getCssClass(),
      headerFilter: this.createHeaderFilter(),
      format: this.field.getFieldFormat(),
      editorOptions: {
        onInitialized: this.onInitialized,
        useMaskBehavior: !weekOrMonth,
        acceptCustomValue: !weekOrMonth,
        dropDownButtonTemplate: getDateBoxIconTemplate(this.field.isTimeAllowed),
        calendarOptions: {
          ...minMaxZoomLevel(this.field.dateSelectMode)
        }
      },
      customizeText: this.field.viewFormat && null,
      dateSelectMode: this.field.dateSelectMode,
      allowInputData: !weekOrMonth,
      isTimeAllowed: this.field.isTimeAllowed,
      cellTemplate: cellTemplate,
      editCellTemplate: 'date-column-editor-template'
    };
  }
}
