import {Column} from 'components/TableCoreOld/columns/index';
import {isNumeric} from 'services/SecondaryMethods/typeUtils';
import HeaderFilterDataSource from './HeaderFilterDataSource';
import {formatText} from '../../../utilsOld/valueCasters';
import {ColumnClass} from '../types';

export default class NumberColumn extends Column {
  textPretty = text => {
    if (!isNumeric(text)) return null;
    return formatText('number', text, this.regSetting, this.field.getFieldFormat());
  };

  headerFilterLoadFun = instance => {
    const ds = new HeaderFilterDataSource({
      loadRows: instance.getAllRowsData.bind(instance),
      valueGetter: this.headerFilterValueGetter,
      textPrettier: this.textPretty
    });
    return ds.createLoadFun();
  };

  setTextFormat = () => {
    const format = this.field.getFieldFormat() ?? 'decimal';
    if (!format) return;
    return {
      format
    };
  };

  prop() {
    const props = super.prop();
    const textFormat = this.setTextFormat();

    return {
      ...props,
      dataType: 'number',
      cssClass: ColumnClass.Number,
      editorOptions: {
        step: 0
      },
      headerFilter: this.createHeaderFilter(),
      ...textFormat
    };
  }
}
