import {produce} from 'immer';
import {SavedGroupArrProps, SavedGroupProps} from 'services/SecondaryMethods/formItems/getGroups';
import {ITbButtonsPosition} from 'components/TableCoreOld/ColumnSettings/utils';
import {ITabsForLS} from 'components/Layout/LayoutGroups/utils/tabSettings';
import {isEmptyObject} from 'utilsOld/helpers';
import {ISaveQrProps} from 'components/newReactFormFields/QRControl/useSaveQrCamParams';
import {createFormStorageKey} from 'utilsOld/storageKeys';
import {AbstractStateStorage} from 'utilsOld/settingsStorage/AbstractStateStorage';
import {isDefined, isEmptyValue} from '../../services/SecondaryMethods/typeUtils';
import {ToggleTotalButton} from '../../components/table/TableToolBarDirector';
import {BUTTON_TYPE} from '../../services/interfaces/global-interfaces';
import {OneOrZero} from '../storageConverter/convertColumns';
import {booleanToNumber, numberToBoolean} from '../storageConverter/utils';
import {maximizeTBButtons, MinimizeTBButton, minimizeTBButtons} from '../storageConverter/convertTBButtons';
import {showErrorNotification} from '../../services/SecondaryMethods/snackbars';
import {Messages} from '../../services/lang/messages';

export interface IFormStateManager {
  formKey: string;
  userID: number;
  storage?: Storage;
}

export interface MinimizeFormState {
  l?: {
    g?: Record<number, SavedGroupProps>;
  };
  w: number | string;
  h: number | string;
  fo: OneOrZero;
  tb?: MinimizeTBButton[];
  t: ITabsForLS[];
  fd: Record<string, {width: number; height: number}>;
  smd: {width: number; height: number};
  qc: ISaveQrProps;
  trk?: ToggleTotalButton;
  trv?: OneOrZero;
  wbt?: number;
}

export interface MaximizeFormState {
  layout: {
    groups?: Record<number, SavedGroupProps>;
  };
  width: number | string;
  height: number | string;
  firstOpen: boolean;
  tbButtons: ITbButtonsPosition[];
  tabs: ITabsForLS[];
  fieldsDimensions: Record<string, {width: number; height: number}>;
  sidebarModalDimensions: {width: number; height: number};
  'qr-cam': ISaveQrProps;
  totalRowKind?: ToggleTotalButton;
  totalRowVisible?: boolean;
  widthByTitle?: number;
}

/**
 * Класс отвечающий за сохранение настроек формы
 * (по умолчанию сохраняет их в localstorage)
 */
export default class FormStateManager extends AbstractStateStorage {
  private readonly _formKey: string;
  private _state: MinimizeFormState;

  constructor({formKey, userID, storage = localStorage}: IFormStateManager) {
    super({storage, userID});
    this._formKey = formKey;

    this._state = this.load();
    this.write();
  }

  /**
   * Записывает данные в стор
   */
  private write() {
    if (!this._formKey || !this._userID) return;

    try {
      this._storage.setItem(
        this.lsName,
        JSON.stringify(this._state, (_, value) => {
          if (isEmptyValue(value)) return undefined;
          return value;
        })
      );
    } catch(_) {
      showErrorNotification({msg: Messages.Errors.DataSavingError});
    }
  }

  /**
   * Достает все данные из стора
   */
  private load() {
    let state = JSON.parse(this._storage.getItem(this.lsName) || '{}') as MinimizeFormState;
    state.fo = booleanToNumber(isEmptyObject(state)) as OneOrZero;
    state.l =
      state.l && state.l.g
        ? state.l
        : ({
            g: {}
          } as MinimizeFormState['l']);
    state.tb = state.tb ?? [];
    state.t = state.t ?? [];
    state.fd = state.fd ?? {};
    state.qc = state.qc ?? {};
    return state;
  }

  private setInProperty(update: (draft: MinimizeFormState) => void): void {
    this._state = produce(this._state, draft => {
      update(draft);
    });
  }

  protected calcKey(user: number, formKey: string) {
    return createFormStorageKey(user, formKey);
  }

  /**
   * Ключ, по которому хранятся данные формы в сторе
   */
  get lsName(): string {
    return this.calcKey(this._userID, this._formKey);
  }

  /**
   * Достанет из стора настройки групп
   */
  getSavedGroupArrProps(): SavedGroupArrProps {
    return this._state.l?.g ?? {};
  }

  /**
   * Сохранит настройки группы по ID
   */
  saveGroupProps(id: number, value: SavedGroupProps) {
    this.setInProperty(draft => {
      if (draft.l?.g) {
        draft.l.g[id] = value;
      }
    });
    this.write();
  }

  saveTBButtonsPosition(value: ITbButtonsPosition[]) {
    this.setInProperty(draft => (draft.tb = minimizeTBButtons(value)));
    this.write();
  }

  getTBButtonsPosition() {
    return maximizeTBButtons(this._state['tb'] ?? []);
  }

  saveWidthByTitle(widthByTitle: number) {
    this.setInProperty(draft => (draft['wbt'] = widthByTitle));
    this.write();
  }

  getWidthByTitle() {
    return this._state['wbt'];
  }

  saveQRCam(qrCam: ISaveQrProps) {
    this.setInProperty(draft => (draft['qc'] = qrCam));
    this.write();
  }

  getQRCam() {
    return this._state['qc'];
  }

  saveTotalRowKind(button: ToggleTotalButton | null) {
    this.setInProperty(draft => {
      if (!button) {
        delete draft['trk'];
        return;
      }

      draft['trk'] = button;
    });
    this.write();
  }

  getTotalRowKind(): ToggleTotalButton | undefined {
    const kind = this._state['trk'];
    if (kind !== BUTTON_TYPE.TOGGLE_TOTALS_BY_SELECTED && kind !== BUTTON_TYPE.TOGGLE_TOTALS_ALL_DATA) return;
    return kind;
  }

  saveTotalRowVisible(visible?: boolean) {
    this.setInProperty(draft => {
      if (!isDefined(visible)) {
        delete draft['trv'];
        return;
      }

      draft.trv = booleanToNumber(visible);
    });
    this.write();
  }

  getTotalRowVisible(): boolean | undefined {
    return numberToBoolean(this._state.trv);
  }

  savePopUpDimensions({width, height}: {width: number; height: number}) {
    this.setInProperty(draft => {
      draft.w = width;
      draft.h = height;
    });

    this.write();
  }

  getPopUpDimensions() {
    return {
      width: this._state['w'],
      height: this._state['h']
    };
  }

  saveFieldDimensions({fieldName, width, height}: {fieldName: string; width: number; height: number}) {
    this.setInProperty(draft => (draft['fd'][fieldName] = {width, height}));
    this.write();
  }

  getFieldDimensions(fieldName: string): {width: number; height: number} {
    return this._state['fd'][fieldName];
  }

  saveSidebarModalDimensions({width, height}: {width: number; height: number}) {
    this.setInProperty(draft => (draft['smd'] = {width, height}));
    this.write();
  }

  getSidebarModalDimensions(): {width: number; height: number} {
    return this._state['smd'] ?? {width: undefined, height: undefined};
  }

  get firstOpen() {
    return this._state.fo;
  }
}
