import {BUTTON_POSITION} from '../../services/interfaces/global-interfaces';
import {ELayoutType} from '../../services/SecondaryMethods/formItems/itemInterfaces';
import {ITbButtonsPosition} from '../../components/TableCoreOld/ColumnSettings/utils';

export interface MinimizeTBButton {
  i: number;
  p: BUTTON_POSITION;
  it: Exclude<ELayoutType, 'button | group'>;
  t: string;
}

type MaximizeTBButtons = ITbButtonsPosition[];

export const minimizeTBButtons = (tbButtons: MaximizeTBButtons): MinimizeTBButton[] => {
  return tbButtons.map(btn => {
    const isAlreadyMinimized = Object.keys(btn).some((key) => ['i', 'p'].includes(key));

    if (isAlreadyMinimized) return btn as unknown as MinimizeTBButton;

    return ({
      i: btn.id,
      p: btn.position,
      it: btn.itemType,
      t: btn.title
    })
  })
}

export const maximizeTBButtons = (tbButtons: MinimizeTBButton[]): MaximizeTBButtons => {
  return tbButtons.map(btn => ({
    id: btn.i,
    position: btn.p,
    itemType: btn.it,
    title: btn.t,
  }))
}
