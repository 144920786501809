import {Group} from 'utilsOld/systemObjects';
import {fields} from 'services/objects';
import store from 'store';
import {
  ButtonColorScheme,
  ButtonCreators,
  ButtonStylingMode,
  ELayoutType,
  IGroupOptions,
  ILayoutItem,
  LayoutGroups
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {SysFormGroup} from 'services/interfaces/sysObjects';
import {isLayoutGroup, isTileSectionGroup} from 'utilsOld/helpers';
import {BUTTON_POSITION, GroupType} from '../../interfaces/global-interfaces';
import ButtonPosition from 'utilsOld/ButtonPosition';
import {getTranslatedHint, getTranslatedTitle, textIconOptionsByTitleType} from './utils';
import SettingsStorage from '../../../utilsOld/settingsStorage';
import {createGroupTestId} from '../../../utilsOld/createDataTestId';
import {userIdSelector} from '../../user/selectors';

const {BUTTONS, TOOLBAR_BUTTONS_SECTION} = GroupType;

const isButtonGroup = (groupType: GroupType) => groupType === BUTTONS;
const isSectionGroup = (groupType: GroupType) => groupType === TOOLBAR_BUTTONS_SECTION;

export const filterLayoutGroups = (groups: LayoutGroups) =>
  (groups || []).filter((group: {options: {groupType: GroupType}}) => isLayoutGroup(group.options.groupType));

export const defButtonGroups = (sysGroups: SysFormGroup[]) =>
  (sysGroups || []).filter(
    sysGroup => isButtonGroup(sysGroup[fields.GroupType]) || isSectionGroup(sysGroup[fields.GroupType])
  );

export const filterTileSectionGroups = (groups: LayoutGroups) =>
  (groups || []).filter((group: {options: {groupType: GroupType}}) => isTileSectionGroup(group.options.groupType));

export const filterButtonGroups = (groups: any) =>
  (groups || []).filter(
    (group: any) => isButtonGroup(group.options.groupType) || isSectionGroup(group.options.groupType)
  );

export const filterContextMenuGroups = (groups: LayoutGroups) =>
  groups.filter(group => new ButtonPosition(group.options.position!).isInContextMenu());

export const filterToolbarGroups = (groups: LayoutGroups) =>
  groups.filter(group => new ButtonPosition(group.options.position!).isInToolbar() && group.isVisible);

export const checkGroupsInLS = (groups: LayoutGroups, formKey: string) => {
  const userId = userIdSelector(store.getState());
  const formStateManager = SettingsStorage.getFormStateStorage({formKey, userID: userId});
  const buttonsFromLS = formStateManager.getTBButtonsPosition();

  if (buttonsFromLS) {
    return groups.map(group => {
      const btn = buttonsFromLS.find(b => b.itemType === ELayoutType.GROUP && b.id === group.id);
      if (btn) {
        return {
          ...group,
          options: {
            ...group.options,
            position: btn.position
          }
        };
      }
      return group;
    });
  }

  return groups;
};

export type SavedGroupProps = {
  defaultWidth?: number | string;
  defaultHeight?: number | string;
  width?: number;
  height?: number;
};

export type SavedGroupArrProps = Record<string, SavedGroupProps>;
const {TAB, PANEL} = GroupType;

export const getGroups = (
  isFormBodyScrollable: number | boolean | undefined,
  Sys_FormGroups: SysFormGroup[],
  savedGroupArrProps?: SavedGroupArrProps,
  defaultTitleWidth?: string
): ILayoutItem<IGroupOptions>[] => {
  let groups = Sys_FormGroups || [];
  return groups.map((sysGroup): ILayoutItem<IGroupOptions> => {
    const group = new Group(sysGroup);
    const savedProps = (savedGroupArrProps && savedGroupArrProps[group.id]) || {};
    const {icon, title} = textIconOptionsByTitleType(group);

    function checkGroupTypeAndScrollable(savedIsFormBodyScrollable: any, group: any) {
      if (group.groupType === TAB || group.groupType === PANEL) {
        if (savedIsFormBodyScrollable === 1) {
          return 0;
        } else {
          return group.IsGroupBodyScrollable;
        }
      } else {
        return null;
      }
    }
    const groupOptions: IGroupOptions = {
      colorScheme: group.colorScheme ?? ButtonColorScheme.MainScheme,
      stylingMode: group.stylingMode ?? ButtonStylingMode.None,
      defaultIcon: group.icon,
      displayCustomParam: group.displayCustomParam,
      defaultTitle: getTranslatedTitle(group.translations) || group.title,
      icon: icon,
      title,
      groupType: group.groupType,
      isCollapsed: group.isCollapsed,
      isCustomizable: group.isCustomizable,
      isActive: group.isActive,
      isGroup: true,
      isShowTitle: group.isShowTitle,
      orientation: group.orientationStr,
      splitter: group.splitter,
      titleType: group.titleType,
      beginGroup: group.isStartToolbarSection,
      docUrl: group.docUrl,
      hint: getTranslatedHint(group.translations) || group.hint,
      width: group.groupWidth,
      formID: group.formID,
      helpArticleGuid: group.helpArticleGuid,
      tabPosition: group.tabPosition,
      fieldTitleDefaultWidth: group.fieldTitleDefaultWidth || defaultTitleWidth,
      IsGroupBodyScrollable: checkGroupTypeAndScrollable(isFormBodyScrollable, group),
      defaultWidth: savedProps.defaultWidth,
      defaultHeight: savedProps.defaultHeight,
      isResizable: group.isResizable,
      titleFontStyle: group.titleFontStyle,
      verticalAlign: group.verticalAlign,
      horizontalAlign: group.horizontalAlign,
      dataTestId: createGroupTestId(group.formID, group.name)
    };

    if (isButtonGroup(group.groupType) || isSectionGroup(group.groupType)) {
      groupOptions.position = BUTTON_POSITION.ALL;
    }

    return {
      creator: ButtonCreators.FORM,
      id: group.id,
      isVisible: group.isVisible,
      itemType: ELayoutType.GROUP,
      name: group.name,
      cols: group.cols,
      offset: group.offset,
      newLine: group.newLine,
      location: group.dockingSide,
      options: groupOptions,
      order: group.order,
      parentID: group.parentGroupID
    };
  });
};
