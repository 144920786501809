import {GlobalEvents, GlobalEventsActions, GlobalEventsCallbackTypes, GlobalEventsData} from './types';

const {
  DrawerOpened,
  DrawerResizeEND,
  DrawerClosed,
  FormFilterApply,
  ModalResizeEnd,
  ModalShown,
  SplitterResizeEnd,
  AfterScrollClose,
  WindowResize
} = GlobalEvents;

class GlobalEventListener {
  events: GlobalEventsData = {
    [DrawerOpened]: [],
    [DrawerResizeEND]: [],
    [DrawerClosed]: [],
    [FormFilterApply]: [],
    [ModalResizeEnd]: [],
    [ModalShown]: [],
    [SplitterResizeEnd]: [],
    [AfterScrollClose]: [],
    [WindowResize]: []
  };

  subscribe<T extends GlobalEvents>(eventName: T, cb: GlobalEventsCallbackTypes[T]): void {
    this.events[eventName] = this.events[eventName] || [];
    // @ts-ignore
    this.events[eventName].push(cb);
  }

  unsubscribe<T extends GlobalEvents>(eventName: T, cb: GlobalEventsCallbackTypes[T]): void {
    if (this.events[eventName]) {
      // @ts-ignore
      this.events[eventName] = this.events[eventName].filter(fn => fn !== cb);
    }
  }

  publish<T extends GlobalEvents>(eventName: T, data: GlobalEventsActions[T]): void {
    // @ts-ignore
    this.events[eventName]?.forEach(fn => fn(data));
  }
}

const globalEventListener = new GlobalEventListener();
export default globalEventListener;
