import {showErrorNotification} from '../services/SecondaryMethods/snackbars';
import {Messages} from '../services/lang/messages';

const userDataName = (userID: number) => {
  return userID + '-userData';
};

export const saveUserData = (userId: number, data: any) => {
  if (!data) return clearUserData(userId);
  try {
    window.localStorage.setItem(userDataName(userId), JSON.stringify(data));
  } catch (_) {
    showErrorNotification({msg: Messages.Errors.DataSavingError});
  }
};

export const readUserData = (userId: number) => {
  const data = window.localStorage.getItem(userDataName(userId));
  return data && JSON.parse(data);
};

export const clearUserData = (userId: number) => {
  window.localStorage.removeItem(userDataName(userId));
};
