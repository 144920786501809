import {AbstractStateStorage, StorageStingified} from './AbstractStateStorage';
import getFiltersKey from '../../services/SecondaryMethods/filterUtils/getFiltersKey';
import {isDefined} from '../../services/SecondaryMethods/typeUtils';
import {copyFilters} from '../../services/SecondaryMethods/filterUtils';

export default class FilterStateStorage extends AbstractStateStorage {
  private _formKey: string;

  constructor({userID, formKey}: {userID?: number; formKey: string}) {
    super({userID: userID!});
    this._formKey = formKey;
  }

  protected calcKey(userID: number, formKey: string): string {
    return isDefined(userID) ? getFiltersKey(formKey, () => userID) : getFiltersKey(formKey);
  }

  public copyFilters(formKey: string, toLocalStorage: boolean = true, userId?: number) {
    copyFilters(formKey, toLocalStorage, userId && (() => userId));
  }

  public exportAll(): Promise<StorageStingified> {
    if (!this._userID) throw new TypeError('UserId is not specified');

    const key = this.calcKey(this._userID, '');

    for (let i = 0; i < sessionStorage.length; i++) {
      const itemKey = sessionStorage.key(i) ?? '';
      this.validateKey(key, itemKey) && this.copyFilters(itemKey.replace(key, ''), true, this._userID);
    }

    return super.exportAll();
  }

  public importAll(states: StorageStingified = [], convertFunc?: (state: string) => string): Promise<void> {
    return super.importAll(states, convertFunc).then(() => {
      for (const [key, state] of states) {
        sessionStorage.setItem(this._userID + key, state);
      }
    });
  }

  get lsName(): string {
    return this.calcKey(this._userID, this._formKey);
  }

  static copyFilters(formKey: string, toLocalStorage?: boolean, userID?: number) {
    new FilterStateStorage({userID, formKey}).copyFilters(formKey, toLocalStorage, userID);
  }

  static lsName(formKey: string, userID?: number) {
    return new FilterStateStorage({userID, formKey}).lsName;
  }
}
