export const DefaultIcons = {
  MATERIAL_REFRESH: 'fontawesome:far fa-sync-alt',
  MATERIAL_SETTINGS: 'fontawesome:far fa-sliders-v-square',
  MATERIAL_CLEAR: 'fontawesome:far fa-times',
  MATERIAL_DONE: 'fontawesome:far fa-check',
  MATERIAL_ADD_CIRCLE_OUTLINE: 'fontawesome:far fa-plus-circle',
  MATERIAL_DELETE_FOREVER: 'fontawesome:far fa-minus-circle',
  MATERIAL_PRINT: 'fontawesome:far fa-print',

  MATERIAL2_ARROW_UP_BOLD: 'fontawesome:far fa-arrow-alt-up',
  MATERIAL2_ARROW_DOWN_BOLD: 'fontawesome:far fa-arrow-alt-down',
  MATERIAL2_ARROW_LEFT_BOLD: 'fontawesome:far fa-arrow-alt-left',
  MATERIAL2_ARROW_RIGHT_BOLD: 'fontawesome:far fa-arrow-alt-right',

  FA_COPY: 'fontawesome:far fa-copy',
  SVG_COPY: 'svg:./icons/icon-copy.svg',
  FA_TRASH_XMARK: 'fontawesome:far fa-trash-xmark',
  FA_TRASH: 'fontawesome:far fa-trash',
  FA_BOOKMARK_SLASH: 'fontawesome:far fa-bookmark-slash',
  FA_STAR_SHARP: 'fontawesome:far fa-star-sharp-half-stroke',
  SVG_REMOVE_FROM_FAVORITES: 'svg:./icons/icon-stars.svg',
  FA_PEN_FIELD: 'fontawesome:far fa-pen-field',
  FA_CLOSE: 'fad fa-times-square',
  FA_SQUARE: 'fontawesome:select-all-button far fa-square',
  FA_BOOKMARK: 'fontawesome:fas fa-bookmark',
  FA_BOOKMARK_WITHOUT_FILL: 'fontawesome: fa-light fa-bookmark',
  FA_BOOKMARK_NOT_OPEN: 'svg:./icons/icon-bookmark.svg',
  FA_TRASH_ICON: 'svg:./icons/icon-trash.svg',
  FA_SQUARE_SOLID: 'fontawesome:fas fa-square',
  FA_ARROW_LEFT: 'fontawesome:far fa-chevron-left',
  FA_EDIT: 'fontawesome:far fa-edit',
  FA_PRINT: 'fontawesome: far fa-print',
  FA_CHECK_SQUARE: 'fontawesome:select-all-button far fa-check-square',
  FA_FILTER_MANAGER: 'fontawesome:far fa-list',
  FA_FAR_LIST_CHECK: 'fontawesome:far fa-list-check',
  FA_FAR_FILE_EXPORT: 'fontawesome:fa-regular fa-file-xls',
  FA_COPY_FILTER_AS_URL: 'fontawesome:far fa-link',
  FA_SHOW_FILTER_DOCK_PANEL: 'fontawesome:far fa-folder-tree',
  FA_SAVE: 'fontawesome:far fa-save',
  FA_SEARCH: 'fontawesome:far fa-search',
  FA_FILE_SET_PASSED: 'fontawesome:fal fa-file-check',
  FA_FILE_CLEAR_PASSED: 'fontawesome:fal fa-file-times',
  FA_EXTERNAL_LINK: 'fontawesome:far fa-external-link-alt',
  FA_FOLDER: 'fontawesome:fas fa-folder',
  FA_FOLDER_REGULAR: 'fontawesome:far fa-folder',
  FA_GENDERLESS: 'fontawesome:fas fa-genderless',
  FA_BARCODE: 'far fa-barcode-read',
  FA_BARS: 'fontawesome:fa-regular fa-bars',
  FA_FAVORITE: 'fontawesome:fa-star fas favorite',
  FA_CHEVRON_LEFT: 'far fa-chevron-left',
  FA_CHEVRON_RIGHT: 'far fa-chevron-right',
  FA_USER: 'fal fa-user',
  FA_SIGN_OUT_ALT: 'fas fa-sign-out-alt',
  FA_USER_SHIELD: 'fa-regular fa-user-shield',
  FA_HOUSE: 'fa-regular fa-house',
  FA_PEN_RULER: 'fa-regular fa-pen-ruler',
  FA_BRIEFCASE: 'fa-regular fa-briefcase',
  FA_COG: 'fa-regular fal fa-cog',
  FA_MOBILE: 'fa-regular fa-mobile',
  FA_KEY: 'fa-regular fa-key',
  FA_CIRCLE_BOLT: 'fa-regular fa-circle-bolt',
  FA_INFO: 'fa-regular fa-circle-info',
  FA_EXCLAMATION: 'fa-regular fa-circle-exclamation',
  FA_EYE: 'fa-solid fa-eye',
  FA_EYE_SLASH: 'fa-sharp fa-solid fa-eye-slash',
  DE_EXPORT: 'fontawesome:far fa-download',
  FA_CALC_TOTALS_BY_SELECTED: 'fontawesome:far fa-sigma',
  FA_STAR: 'fontawesome:fa-regular fa-circle-star',
  FA_SQUARE_BOLT: 'fontawesome:fa-regular fa-square-bolt custom-icon',
  FA_FILTER_LIST: 'fontawesome: fa-sharp fa-regular fa-filter-list',
  FA_BROWSER: 'fa-regular fa-browser',
  FA_DESKTOP: 'fa-regular fa-desktop',
  FA_SIDEBAR: 'fa-regular fa-sidebar',
  FA_ARROWS_MAXIMIZE: 'fa-regular fa-arrows-maximize',
  FA_ARROWS_MINIMIZE: 'fa-regular fa-arrows-minimize',

  DE_REVERT: 'devextreme:revert',
  DE_EXPORT_SELECTED: 'devextreme:exportselected',
  DE_EXPORT_ALL: 'devextreme:exportxlsx',
  DE_CHOOSER: 'devextreme:column-chooser',
  DE_COLUMNS_SETTINGS: 'devextreme:columnfield',

  SVG_REMOVE_FILTER: 'svg:./icons/clear-filter.svg',
  SVG_SECTION_MODE: 'svg:./icons/sections-mode.svg'
};
