import {ToolBarButtonOptions, ToolBarItem, ToolBarItemWidget} from 'utilsOld/toolbar_buttons';
import {system} from 'services/objects';

const {ICON} = system.TITLE_TYPE;

const toolBarButtonProps = (options: ToolBarButtonOptions): ToolBarItem => {
  const {locateInMenu, disabled, location, visible, widget, items} = options;

  const newTitleType = options.titleType ?? ICON;

  //Якщо у кнопки нема жодної вкладеної видимої кнопки, то сама кнопка стає неактивною.
  const updatedDisabled = widget === ToolBarItemWidget.Menu && !items?.length ? true : disabled;

  return {
    beginGroup: false,
    disabled: updatedDisabled,
    locateInMenu,
    location,
    visible,
    options: {
      ...options,
      disabled: updatedDisabled,
      titleType: newTitleType,
      location: options.location || 'before',
      locateInMenu: options.locateInMenu || 'auto',
      onClick: (comData: Record<string, any>): void => {
        if (typeof options.onClickCustom === 'function')
          options.onClickCustom(comData.event, options.action, options.id);
      }
    },
    widget: ToolBarItemWidget.Button
  };
};

export default toolBarButtonProps;
