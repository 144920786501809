import {HashRequestBody, IDataSourceCacheStore, LoadResult} from './consts';

export default class DataSourceCacheController {

  private _cache: IDataSourceCacheStore;
  private _hash = '';
  private _lastLoadedPage = 0;
  private _firstLoadedPage = 0;
  private _loadedPages: number[] = [];

  constructor(cacheStore: IDataSourceCacheStore) {
    this._cache = cacheStore;
  }

  setCacheStore(value: IDataSourceCacheStore) {
    this._cache = value;
  }

  private calcDataHash({Filters, Sorts, Params}: HashRequestBody) {
    return JSON.stringify({Filters, Sorts, Params});
  }

  get lastLoadedPage(): number {
    return this._lastLoadedPage;
  }

  set lastLoadedPage(value: number) {
    this._lastLoadedPage = value;

    if (value === -1) {
      this._firstLoadedPage = value;
    }

    if (!this._firstLoadedPage) {
      this._firstLoadedPage = value;
    }
  }

  get firstLoadedPage(): number {
    return this._firstLoadedPage;
  }

  set hash(req: HashRequestBody) {
    this._hash = this.calcDataHash(req);
  }

  hasHash(req: HashRequestBody) {
    return this._hash === this.calcDataHash(req);
  }

  clearCache() {
    this._cache.clear();
    this._lastLoadedPage = 0;
    this._firstLoadedPage = 0;
    this._loadedPages = [];
    this._hash = '';
  }

  replaceCache(data: LoadResult['data']) {
    this._cache.replaceData(data);
  }

  appendDataToCache(data: LoadResult['data'], loadedPage: number) {
    if (loadedPage === -1) {
      this.lastLoadedPage = loadedPage;
      return this.replaceCache(data);
    }

    if (loadedPage > this._lastLoadedPage) {
      this.lastLoadedPage = loadedPage;
      return this._cache.appendData(data);
    }

    this._loadedPages.push(loadedPage);

    this._firstLoadedPage = loadedPage;
    this._cache.insertData(data);
  }

  setSummary(summary: LoadResult['summary']) {
    this._cache.summary = summary;
  }

  get summary() {
    return this._cache.summary;
  }

  load(skip: number, take: number) {
    if (take === -1) {
      return this.loadAll();
    }

    return this._cache.take(skip, skip + take);
  }

  loadAll() {
    return this._cache.take(0, this._cache.rowsCount());
  }
}
